import "cm-chessboard/styles/cm-chessboard.css";
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


import {COLOR, MOVE_INPUT_MODE, MARKER_TYPE, INPUT_EVENT_TYPE, Chessboard} from "cm-chessboard";
const customMarkers = {
  emphasize2:{
    slice:"marker3",
    class:"markers"
  }
};

const useStyles = makeStyles((theme) => { 
        return({
            mobileBoard: 
            { 
                width: "90%"
            },
            desktopBoard:
            {
                width: "60%"
            }
        })
    });
  

const contextHandler = (newGame) => (event) => {
  console.log(event)
  const markersOnSquare = newGame.getMarkers(event.square, MARKER_TYPE.emphasize)
  if (markersOnSquare.length > 0) {
    newGame.removeMarkers(event.square, MARKER_TYPE.emphasize)
  } else {
    newGame.addMarker(event.square)
  }
}




const Board = ({moves, move, fen, history, setHistory}) => {
    const classes = useStyles();
  useEffect(()=>{
    const newGame = new Chessboard(document.getElementById("board"), {
      position: "start",
      sprite: {url: "../assets/images/chessboard-sprite.svg"},
      orientation: COLOR.white,
      moveInputMode: MOVE_INPUT_MODE.dragPiece,
      responsive: true,
      style: {
        cssClass: "newBoard",
        showCoordinates: true, // show ranks and files
        showBorder: false, // display a border around the board
        //aspectRatio: 0.9
    },
  });
  newGame.enableMoveInput(inputHandler);
  newGame.enableContextInput(contextHandler(newGame))

  return () => {
    newGame.destroy();
  }
  },[]);





  const inputHandler = (event) => {
    console.log("event", event);
    if (event.type === INPUT_EVENT_TYPE.moveDone) {


      const newMove = {from: event.squareFrom, to: event.squareTo, promotion: "q"}
      const result = move(newMove, {sloppy: true})
      console.log(result);
      event.chessboard.removeMarkers();
      if (result) {

          event.chessboard.disableMoveInput()
           setTimeout(() => {
                event.chessboard.setPosition(fen())
                const possibleMoves = moves({verbose: true})
              if (possibleMoves.length > 0) {
                  let random = Math.random() * (possibleMoves.length-1);
                  const randomMove = possibleMoves[Math.floor(random)];
                  let blackMove = move({from: randomMove.from, to: randomMove.to});
                  event.chessboard.enableMoveInput(inputHandler, COLOR.white)
                  event.chessboard.setPosition(fen())
                  setHistory(history)
              }
          }) 
      } else {
          console.log("invalid move", move)
      }
       
        return result;
      }
      else if(event.type === INPUT_EVENT_TYPE.moveCanceled) {
          event.chessboard.removeMarkers();
        return true;
    } 
      else if(event.type === INPUT_EVENT_TYPE.moveStart) {
        let {square} = event;
        let possibleMoves = moves({square, verbose: true});
        for (let p of possibleMoves ) {
         
          event.chessboard.addMarker(p.to, customMarkers.emphasize2);
        }
        return possibleMoves;
    } else {
        return true
    }
  }
  let isMobile = useMediaQuery('(max-width:600px)');
return (
  <div id="board" className={isMobile ? classes.mobileBoard : classes.desktopBoard}></div>
)
};




export default Board;
