import './App.css';
import React, {useState} from 'react';
import Chess from "chess.js";

import { makeStyles,createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';
import { Container, Paper} from '@material-ui/core';
//import {Menu} from "@material-ui/icons";

import Board from "./components/Board";
import ChessAppBar from "./components/ChessAppBar";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#000000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});


const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center"
  },
  history: {
    width: "25%",
    padding: "10px",
  }
}));



const HistoryDisplay = ({history}) => {
  const classes = useStyles();
  return (
  <div className={classes.history}>
    <h2>Moves played</h2>
    <p>{history.join(" - ")}</p>
  </div>
  )
}


const ChessUI = ({chess, history, setHistory}) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Container>
        <Paper className={classes.paper}>
          <Board move={chess.move} moves={chess.moves} fen={chess.fen} history={chess.history} setHistory={setHistory}/>
          <HistoryDisplay history={history}/>
        </Paper>
      </Container>
    </div>
  )
}


function App() {
const [chess, setChess] = useState(new Chess());
const [history, setHistory] = useState([]);

  return (
    <ThemeProvider theme={theme}>
    <ChessAppBar/>
    <ChessUI history={history} setHistory={setHistory} chess={chess}/>
    </ThemeProvider>
  );
}

export default App;
